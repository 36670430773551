<template>
	<div>
		<multiselect
			id="tags"
			v-model="selectedTags"
			label="text"
			track-by="value"
			open-direction="bottom"
			tag-position="bottom"
			:options="tags"
			name="tags"
			multiple
			searchable
			hide-selected
			:loading="tagLoading"
			:internal-search="false"
			:close-on-select="false"
			:options-limit="20"
			:taggable="!tagLoading"
			:placeholder="placeholder"
			@search-change="asyncFind"
			@tag="addTag">
			<template
				#selection="{ values, searching, isOpen }">
				<span
					v-if="values.length && !isOpen"
					class="multiselect__single">
					{{ translate('tags_selected', { count: selectedTags.length }) }}
				</span>
			</template>
			<template slot="noOptions">
				{{ translate('no_options_placeholder') }}
			</template>
			<span slot="noResult">{{ translate('data_not_found') }}</span>
		</multiselect>
		<template v-if="hasError">
			<span
				v-for="error in errors"
				:key="error"
				class="custom-invalid-feedback animated fadeIn"
				v-text="error" />
		</template>
		<div
			v-if="selectedTags.length > 0"
			role="alert"
			class="a mb-3 mt-2 p-1 px-3 text-left text-small multiselect-alert">
			{{ getAllTags() }}
		</div>
	</div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import {
	Tags,
} from '@/translations';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Tag from '@/util/Tag';

export default {
	name: 'TagMultiselect',
	messages: [Tags],
	components: { Multiselect },
	props: {
		initialValues: {
			type: Array,
			default() {
				return [];
			},
		},
		placeholder: {
			type: String,
			default: '',
		},
		errorsImport: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data() {
		return {
			tags: [],
			errors: this.errorsImport,
			hasError: false,
			tagsInfo: new Tag(),
			selectedTags: [],
			isLoading: false,
		};
	},
	computed: {
		tagLoading() {
			return this.isLoading;
		},
		onlyTags() {
			return this.selectedTags.map((item) => item.value.trim());
		},
	},
	watch: {
		initialValues() {
			this.selectedTags = this.initialValues;
		},
		selectedTags() {
			this.updateTags();
		},
		errorsImport(value) {
			this.errors = value;
			this.hasError = value.length > 0;
		},
	},
	methods: {
		asyncFind(query) {
			if (this.timeToSearch) {
				clearTimeout(this.timeToSearch);
			}
			this.isLoading = true;
			this.timeToSearch = setTimeout(() => {
				this.tags = this.selectedTags;
				if (query.length < 1) {
					this.isLoading = false;
					return [];
				}
				const options = { name: query };
				this.tagsInfo.getTags(options).then((data) => {
					let newTags = [];
					data.forEach((item) => {
						if (!this.onlyTags.includes(item.attributes.name)) {
							newTags.push({
								value: item.attributes.name,
								text: item.attributes.name,
							});
						}
					});
					newTags = [...newTags, ...this.selectedTags];
					this.tags = newTags;
				}).catch((err) => {
					this.errors = { ...err.errors };
				}).finally(() => { this.isLoading = false; });

				return this.tags;
			}, 300);
		},
		addTag(newTag) {
			if (!this.onlyTags.includes(newTag)) {
				const tag = {
					text: newTag,
					value: newTag,
				};
				this.tags.push(tag);
				this.selectedTags.push(tag);
			}
			this.updateTags();
		},
		getAllTags() {
			const selectedNames = this.selectedTags.map((tagAttribute) => tagAttribute.text).join(', ');
			return selectedNames;
		},
		updateTags() {
			this.$emit('tagSelected', this.onlyTags);
		},
	},
};
</script>
<style>
.multiselect-alert {
	background-color: rgb(249 167 65 / 20%);
	border-color: #73818f;
	color: #7d6d6d;
	border-radius: 5px;
}
</style>
