<template>
	<div>
		<treeselect
			v-model="value"
			:class="hasError ? 'is-invalid' : ''"
			:options="items"
			:placeholder="placeholder" />
		<template>
			<slot name="info_message" />
		</template>
		<span
			v-for="error in errors"
			:key="error"
			class="custom-invalid-feedback animated fadeIn"
			v-text="error" />
	</div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
	name: 'TreeSelect',
	components: {
		Treeselect,
	},
	props: {
		errorsImport: {
			type: Array,
			default: () => [],
		},
		importItems: {
			type: Array,
			default: () => [],
		},
		initValue: {
			type: Number,
			default: 0,
		},
		placeholder: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			errors: this.errorsImport,
			items: [],
			value: null,
			hasError: false,
		};
	},
	watch: {
		value() {
			this.$emit('change', this.value);
		},
		errorsImport(value) {
			this.errors = value;
			this.hasError = value.length > 0;
		},
		importItems(value) {
			this.items = value;
		},
		initValue(value) {
			this.value = value;
		},
	},
};
</script>
<style>
.is-invalid > .vue-treeselect__control {
	border: 1px solid #f86c6b;
}
.vue-treeselect__input {
	border: none !important;
}
</style>
