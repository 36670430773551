<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<tree-select
			:errors-import="errors"
			:import-items="categoriesTree"
			:init-value="initialCategory"
			:placeholder="translate('video_category')"
			@change="handleCategoryChange">
			<template
				v-if="categoryChanged && showInfoMessage"
				slot="info_message">
				<small
					class="form-text text-muted"
					v-html="translate('moving_video', { from: categoryPath(initialCategory), to: categoryPath(categoryId) })" />
			</template>
		</tree-select>
	</div>
</template>
<script>
import TreeSelect from '@/components/TreeSelect';
import {
	VideoCategories as VideoCategoriesTranslations,
	HighlightVideos,
} from '@/translations';

export default {
	name: 'CategoriesTreeSelect',
	messages: [
		VideoCategoriesTranslations,
		HighlightVideos,
	],
	components: {
		TreeSelect,
	},
	props: {
		errorsImport: {
			type: Array,
			default: () => [],
		},
		categories: {
			type: Array,
			default: () => [],
		},
		initialCategory: {
			type: Number,
			default: null,
		},
		showInfoMessage: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			errors: this.errorsImport,
			categoriesTree: [],
			categoryChanged: false,
			categoryId: null,
		};
	},
	watch: {
		language() {
			this.categoriesTree = this.formatCategoriesTree();
		},
		errorsImport(value) {
			this.errors = value;
		},
		categories() {
			this.categoriesTree = this.formatCategoriesTree();
		},
		initialCategory(value) {
			this.categoryId = value;
		},
	},
	methods: {
		formatCategoriesTree(parentId = null) {
			let children = this.categories.filter((category) => category.attributes.parent_id === parentId);

			if (children.length > 0) {
				const treeLevel = children.map((category) => ({
					id: category.id,
					label: this.translate(category.attributes.code_name),
				}));

				treeLevel.forEach((level, index) => {
					// Find if current parent has children and add them
					children = this.categories.filter((category) => category.attributes.parent_id === Number(level.id));
					if (children.length > 0) {
						treeLevel[index].children = this.formatCategoriesTree(Number(level.id));
					}
				});
				return treeLevel;
			}
			return [];
		},
		categoryPath(categoryId) {
			const currentCategory = Number(categoryId);
			const selectedCategory = this.categories.find((category) => Number(category.id) === currentCategory);
			let response = '';
			if (typeof selectedCategory !== 'undefined') {
				const parent = this.categories.find((category) => Number(category.id) === Number(selectedCategory.attributes.parent_id));
				if (typeof parent !== 'undefined') {
					response = `${this.translate(parent.attributes.code_name)}/${this.translate(selectedCategory.attributes.code_name)}`;
				} else {
					response = this.translate(selectedCategory.attributes.code_name);
				}
			}
			return response;
		},
		handleCategoryChange(categoryId) {
			this.categoryId = categoryId;
			this.$emit('change', categoryId);
			const selectedCategoryId = Number(categoryId);
			this.categoryChanged = this.initialCategory !== null && selectedCategoryId !== this.initialCategory;
		},
	},
};
</script>
